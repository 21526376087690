@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Eczar&family=Edu+VIC+WA+NT+Beginner&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner&family=Sora:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
section, article, input ,div, textarea, button, img{
  box-sizing: border-box;
}

button{
	cursor: pointer;
}

:root {
	--mainColor: rgb(9 13 32);
	--secondaryColor: white;
	--third-color: #00ffa1;
	--fourthColor: rgb(49 82 255);
	--text: white;
}

section{

}

html {
  scroll-behavior: smooth;
}

img {
	max-width: 100%;
}